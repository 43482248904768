<template>

  <div>

    <b-overlay
      :show="submitting"
      rounded="sm"
    >

      <b-card

        title="Request Events Range"
      >

        <b-form
          ref="requestForm"
          @submit.prevent
        >

          <b-row>

            <b-col cols="4">
              <b-form-group
                label="Start Day"
                label-for="v-startDay"
              >

                <flat-pickr

                  id="v-startDate"
                  v-model="requestsRange.startDate"
                  placeholder="Start Day"
                  required
                  class="form-control"
                />

              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="End Day"
                label-for="v-endDay"
              >

                <flat-pickr

                  id="v-endDay"
                  v-model="requestsRange.endDate"
                  placeholder="End Day"
                  required
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="4">
              <div class="mt-2" />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="submitting"
                @click="requestRange"
              >
                Request events
              </b-button>

            </b-col>

          </b-row>

        </b-form>

      </b-card>

    </b-overlay>
    <b-card-code
      no-body
      title="Ranges(Events)"
    >

      <b-overlay
        :show="loading"
        rounded="sm"
      >

        <b-table
          striped
          hover
          :items="logs"
          :fields="fields"
        >

          <template #cell(id)="data">
            <span v-b-tooltip.hover.top="data.item.id">
              #
            </span>
          </template>

          <template #cell(status)="data">

            <b-badge :variant="data.item.status== 'error' ? 'danger' : data.item.status">
              {{ data.item.status }}
            </b-badge>

          </template>

          <template #cell(days)="row">

            <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
            {{ row.item.days.length }}
            <b-form-checkbox
              v-if="row.item.days.length"
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
            >
              <span>{{ row.detailsShowing ? 'Hide' : 'Show' }} </span>
            </b-form-checkbox>
          </template>

          <!-- full detail on click -->
          <template #row-details="row">
            <b-card>
              <b-table
                :items="row.item.days"
                :fields="childrenFields"
                :tbody-tr-class="'table-primary'"
                striped
                responsive
                size="sm"
                class="mb-0"
              >
                <template #cell(id)="data">
                  <span v-b-tooltip.hover.top="data.item.id">
                    #
                  </span>
                </template>

                <template #cell(tries)="data">
                  <b-badge :variant="data.item.tries > 1 ? 'warning' : 'primary' ">
                    {{ data.item.tries }}
                  </b-badge>
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="getStatusClass( data.item.status)">
                    {{ data.item.status }}
                  </b-badge>
                </template>

                <template #cell(startDate)="data">
                  {{ data.item.startDate|formatDate }}
                </template>

                <template #cell(requestedAt)="data">
                  {{ data.item.requestedAt|formatDateTime }}
                </template>

                <template #cell(run)="data">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    size="sm"
                    :disabled="submitting"
                    @click="requestDay(data.item._id)"
                  >
                    Request
                  </b-button>
                </template>

              </b-table>
            </b-card>
          </template>

          <template #cell(createdAt)="data">
            {{ data.item.createdAt|formatDateTime('UTC') }}
          </template>

          <template #cell(startDate)="data">
            {{ data.item.startDate|formatDate }}
          </template>

          <template #cell(endDate)="data">
            {{ data.item.endDate|formatDate }}
          </template>

          <template #cell(nextTokens)="row">
            <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
            {{ row.item.nextTokens }} <span class="text-warning">{{ row.item.pendingNextTokens ? '(' + row.item.pendingNextTokens + ' pending)' : '' }}</span>
          </template>

        </b-table>

        <div
          v-if="rows > 25"
          class="mt-1 d-flex justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            hide-goto-end-buttons
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
          />
        </div>

      </b-overlay>

    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BCard, BCol, BForm, BRow, BBadge, BFormGroup, BFormInput,
  BFormSelect,
  BFormCheckbox, BButton,
  BPagination, VBTooltip, BOverlay,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BCardCode,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    BBadge,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      requestsRange: {
        type: 'events_shipments',
      },
      loading: false,
      submitting: false,
      currentPage: 1,
      accountId: null,
      logs: [],
      perPage: 25,
      fields: ['id', 'startDate', 'endDate', 'nextTokens', 'totalItems', 'days', 'createdAt'],
      childrenFields: ['id', 'startDate', 'tries', 'status', 'requestedAt', 'run'],
      current: 1,
      rows: 0,
      periodOptions: [
        { value: 'DAILY', text: 'Daily' },
      ],

    }
  },

  mounted() {
    this.accountId = this.$route.params.accountId
    this.requestsRange.accountId = this.accountId
    this.loadOrdersRanges(this.accountId, 1)
  },

  methods: {

    handlePageChange(value) {
      this.page = value
      this.loadOrdersRanges(this.accountId, this.page)
    },

    loadOrdersRanges(accountId, page = 1) {
      this.loading = true
      this.$store.dispatch('amzRangeRequest/fetchAll', { accountId, type: 'events_shipments', page })
        .then(result => {
          this.logs = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    requestRange() {
      this.submitting = true

      this.$store.dispatch('amzRangeRequest/create', this.requestsRange)
        .then(() => {
          this.showToast('Success', 'Requested successfully', 'success')
          this.loadOrdersRanges(this.accountId, this.page)
          this.submitting = false

          this.$refs.requestForm.reset()
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while requesting', 'danger')
          this.submitting = false
        })
    },

    requestDay(dayId) {
      this.loading = true

      this.$store.dispatch('amzRangeRequest/runDay', dayId)
        .then(() => {
          this.showToast('Success', 'Requested successfully', 'success')
          this.loadOrdersRanges(this.accountId, this.page)
          this.loading = false
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while requesting day', 'danger')
          this.loading = false
        })
    },
    getStatusClass(status) {
      const mapp = {
        to_retry: 'warning',
        error: 'danger',
        success: 'success',

      }

      return mapp[status] || null
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
